/** @var { BaseurlState } */
const STATE = {
    data: null,
    // baseUrl: 'http://localhost:8000/api/',
    // baseUrl: 'https://enigmatic-atoll-64574.herokuapp.com/api/',
    // baseUrl: 'https://powerful-basin-71452.herokuapp.com/api/',
    baseUrl: 'https://backend.ovexbroker.co.za/api/',
};

export default STATE;
