<template>
  <div>
    <div v-if="isSubscriber && isSuperUser">

      <div class="d-flex justify-end px-8 ">
        <v-radio-group
            v-model="itemSize"
            row
            class="align-self-center mr-16"
        >
          <v-radio
              label="24 Hours"
              :value="228"
              @click="getCryptoData"
          ></v-radio>
          <v-radio
              label="72 Hours"
              :value="864"
              @click="getCryptoData"
          ></v-radio>
          <v-radio
              label="1 week"
              :value="2016"
              @click="getCryptoData"
          ></v-radio>
          <v-radio
              label="1 month"
              :value="8640"
              @click="getCryptoData"
          ></v-radio>

          <v-radio
              label="3 months"
              :value="25920"
              @click="getCryptoData"
          ></v-radio>

          <v-radio
              label="6 months"
              :value="51840"
              @click="getCryptoData"
          ></v-radio>
          <v-radio
              label="1 year"
              :value="103680"
              @click="getCryptoData"
          ></v-radio>
        </v-radio-group>
      </div>

      <div class="d-flex justify-space-between align-center">

        <v-btn @click="getNextCryptoData"
               :disabled="cryptoData.next===null"
               icon height="500"
               class="ml-2"
               color="primary"
               width="50">
          <v-icon size="100">mdi-chevron-left</v-icon>
        </v-btn>
        <!--      LineChart Component-->
        <div style="width: 95vw;">
          <AltUniswapLineChart :key="linearChartUpdateKey"
                               v-if="timestamps.length > 0"
                               :arbETHData="arbETH"
                               :arbCOMPData="arbCOMP"
                               :arbUSDTData="arbUSDT"
                               :arbDAIData="arbDAI"
                               :arbBATData="arbBAT"
                               :alt_valr_doge_arbData="alt_valr_doge_arb"
                               :alt_valr_trx_arbData="alt_valr_trx_arb"
                               :alt_valr_xrp_arbData="alt_valr_xrp_arb"
                               :alt_valr_ada_arbData="alt_valr_ada_arb"
                               :alt_valr_bat_arbData="alt_valr_bat_arb"
                               :alt_kraken_doge_arbData="alt_kraken_doge_arb"

                               :alt_kraken_comp_arbData="alt_kraken_comp_arb"
                               :alt_kraken_dai_arbData="alt_kraken_dai_arb"
                               :alt_kraken_usdt_arbData="alt_kraken_usdt_arb"
                               :alt_kraken_bat_arbData="alt_kraken_bat_arb"
                               :kraken_valr_doge_arbData="kraken_valr_doge_arb"
                               :kraken_valr_trx_arbData="kraken_valr_trx_arb"
                               :kraken_valr_ada_arbData="kraken_valr_ada_arb"
                               :kraken_valr_xlm_arbData="kraken_valr_xlm_arb"
                               :kraken_valr_bat_arbData="kraken_valr_bat_arb"
                               :kraken_valr_comp_arbData="kraken_valr_comp_arb"
                               :kraken_valr_dai_arbData="kraken_valr_dai_arb"
                               :timestampsData="timestamps"/>
        </div>
        <v-btn
            @click="getPrevCryptoData"
            :disabled="cryptoData.previous===null"
            icon
            height="500"
            class="mr-2"
            color="primary"
            width="50">
          <v-icon size="100">mdi-chevron-right</v-icon>
        </v-btn>
      </div>

      <!--    Secound chart-->

      <div class="d-flex justify-space-between align-center">

        <v-btn @click="getNextCryptoData"
               :disabled="cryptoData.next===null"
               icon height="500"
               class="ml-2"
               color="primary"
               width="50">
          <v-icon size="100">mdi-chevron-left</v-icon>
        </v-btn>
        <!--      LineChart Component-->
        <div style="width: 95vw;">
          <AltUniswapChartTwo :key="linearChartUpdateKey"
                              v-if="timestamps.length > 0"
                              :btc_percentage-data="btc_percentage"
                              :eth_percentage-data="eth_percentage"
                              :ada_percentage-data="ada_percentage"
                              :usdt_percentage-data="usdt_percentage"
                              :comp_percentage-data="comp_percentage"
                              :dai_percentage-data="dai_percentage"
                              :trx_percentage-data="trx_percentage"
                              :xrp_percentage-data="xrp_percentage"
                              :bat_percentage-data="bat_percentage"
                              :doge_percentage-data="doge_percentage"
                              :timestampsData="timestamps"/>
        </div>
        <v-btn
            @click="getPrevCryptoData"
            :disabled="cryptoData.previous===null"
            icon
            height="500"
            class="mr-2"
            color="primary"
            width="50">
          <v-icon size="100">mdi-chevron-right</v-icon>
        </v-btn>
      </div>


      <!--    ETH gas chart-->

      <div class="d-flex justify-space-between align-center">

        <v-btn @click="getNextETHGasData"
               :disabled="ethGasData.next===null"
               icon height="500"
               class="ml-2"
               color="primary"
               width="50">
          <v-icon size="100">mdi-chevron-left</v-icon>
        </v-btn>
        <!--      LineChart Component-->
        <div style="width: 95vw;">
          <EthGasLineChart :key="linearChartUpdateKey"
                           v-if="ethGasTimestamp.length > 0"
                           :fast-gas-price-data="fastGasPrice"
                           :propose-gas-price-data="proposeGasPrice"
                           :safe-gas-price-data="safeGasPrice"
                           :eth-gas-timestamp-data="ethGasTimestamp"/>
        </div>
        <v-btn
            @click="getPrevETHGasData"
            :disabled="ethGasData.previous===null"
            icon
            height="500"
            class="mr-2"
            color="primary"
            width="50">
          <v-icon size="100">mdi-chevron-right</v-icon>
        </v-btn>
      </div>

<!--      eth gas chart end-->

    <v-container fluid>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              BUYING PRICES
            </th>
            <th class="text-left">
              BTC
            </th>
            <th class="text-left">
              ETH
            </th>
            <th class="text-left">
              ADA - Cardano
            </th>
            <th class="text-left">
              USDT - Tether
            </th>
            <th class="text-left">
              COMP
            </th>
            <th class="text-left">
              Dai
            </th>
            <th class="text-left">
              TRX - TRON
            </th>
            <th class="text-left">
              XRP - Ripple
            </th>
            <th class="text-left">
              BAT
            </th>
            <th class="text-left">
              DOGE
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Cheapest Exchange</td>
            <td>
              {{
                getMin(cryptoData.results[0].alt_btc, cryptoData.results[0].valr_btc, cryptoData.results[0].kraken_btc, cryptoData.results[0].luno_btc, cryptoData.results[0].binance_btc)
              }}
            </td>
            <td>
              {{
                getMin(cryptoData.results[0].altETH, cryptoData.results[0].uniETH, cryptoData.results[0].valr_eth, cryptoData.results[0].luno_eth, cryptoData.results[0].binance_eth)
              }}
            </td>
            <td>
              {{
                getMin(cryptoData.results[0].alt_ada, cryptoData.results[0].valr_ada, cryptoData.results[0].kraken_ada, cryptoData.results[0].binance_ada)
              }}
            </td>
            <td>
              {{
                getMin(cryptoData.results[0].altUSDT, cryptoData.results[0].uniUSDT, cryptoData.results[0].kraken_usdt, cryptoData.results[0].binance_usdt)
              }}
            </td>
            <td>
              {{
                getMin(cryptoData.results[0].altCOMP, cryptoData.results[0].uniCOMP, cryptoData.results[0].valr_comp, cryptoData.results[0].kraken_comp, cryptoData.results[0].binance_comp)
              }}
            </td>
            <td>
              {{
                getMin(cryptoData.results[0].altDAI, cryptoData.results[0].uniDAI, cryptoData.results[0].valr_dai, cryptoData.results[0].kraken_dai, cryptoData.results[0].binance_dai)
              }}
            </td>
            <td>
              {{
                getMin(cryptoData.results[0].alt_trx, cryptoData.results[0].valr_trx, cryptoData.results[0].kraken_trx, cryptoData.results[0].binance_trx)
              }}
            </td>
            <td>
              {{
                getMin(cryptoData.results[0].alt_xrp, cryptoData.results[0].valr_xrp, cryptoData.results[0].kraken_xrp, cryptoData.results[0].luno_xrp, cryptoData.results[0].binance_xrp)
              }}
            </td>
            <td>
              {{
                getMin(cryptoData.results[0].altBAT, cryptoData.results[0].uniBAT, cryptoData.results[0].valr_bat, cryptoData.results[0].kraken_bat, cryptoData.results[0].binance_bat)
              }}
            </td>
            <td>
              {{
                getMin(cryptoData.results[0].alt_doge, cryptoData.results[0].valr_doge, cryptoData.results[0].kraken_doge, cryptoData.results[0].binance_doge)
              }}
            </td>
          </tr>
          <tr>
            <td>Most Expensive Exchange</td>
            <td>
              {{
                getMax(cryptoData.results[0].alt_btc, cryptoData.results[0].valr_btc, cryptoData.results[0].kraken_btc, cryptoData.results[0].luno_btc, cryptoData.results[0].binance_btc)
              }}
            </td>
            <td>
              {{
                getMax(cryptoData.results[0].altETH, cryptoData.results[0].uniETH, cryptoData.results[0].valr_eth, cryptoData.results[0].luno_eth, cryptoData.results[0].binance_eth)
              }}
            </td>
            <td>
              {{
                getMax(cryptoData.results[0].alt_ada, cryptoData.results[0].valr_ada, cryptoData.results[0].kraken_ada, cryptoData.results[0].binance_ada)
              }}
            </td>
            <td>
              {{
                getMax(cryptoData.results[0].altUSDT, cryptoData.results[0].uniUSDT, cryptoData.results[0].kraken_usdt, cryptoData.results[0].binance_usdt)
              }}
            </td>
            <td>
              {{
                getMax(cryptoData.results[0].altCOMP, cryptoData.results[0].uniCOMP, cryptoData.results[0].valr_comp, cryptoData.results[0].kraken_comp, cryptoData.results[0].binance_comp)
              }}
            </td>
            <td>
              {{
                getMax(cryptoData.results[0].altDAI, cryptoData.results[0].uniDAI, cryptoData.results[0].valr_dai, cryptoData.results[0].kraken_dai, cryptoData.results[0].binance_dai)
              }}
            </td>
            <td>
              {{
                getMax(cryptoData.results[0].alt_trx, cryptoData.results[0].valr_trx, cryptoData.results[0].kraken_trx, cryptoData.results[0].binance_trx)
              }}
            </td>
            <td>
              {{
                getMax(cryptoData.results[0].alt_xrp, cryptoData.results[0].valr_xrp, cryptoData.results[0].kraken_xrp, cryptoData.results[0].luno_xrp, cryptoData.results[0].binance_xrp)
              }}
            </td>
            <td>
              {{
                getMax(cryptoData.results[0].altBAT, cryptoData.results[0].uniBAT, cryptoData.results[0].valr_bat, cryptoData.results[0].kraken_bat, cryptoData.results[0].binance_bat)
              }}
            </td>
            <td>
              {{
                getMax(cryptoData.results[0].alt_doge, cryptoData.results[0].valr_doge, cryptoData.results[0].kraken_doge, cryptoData.results[0].binance_doge)
              }}
            </td>
          </tr>

          <tr>
            <td>Cheapest Vs Expensive</td>
            <td>{{
                getArb(
                    getMin(cryptoData.results[0].alt_btc,
                        cryptoData.results[0].valr_btc,
                        cryptoData.results[0].kraken_btc,
                        cryptoData.results[0].luno_btc,
                        cryptoData.results[0].binance_btc),

                    getMax(cryptoData.results[0].alt_btc,
                        cryptoData.results[0].valr_btc,
                        cryptoData.results[0].kraken_btc,
                        cryptoData.results[0].luno_btc,
                        cryptoData.results[0].binance_btc))
              }} %
            </td>
            <td>{{
                getArb(
                    getMin(cryptoData.results[0].altETH,
                        cryptoData.results[0].uniETH,
                        cryptoData.results[0].valr_eth,
                        cryptoData.results[0].luno_eth,
                        cryptoData.results[0].binance_eth),
                    getMax(cryptoData.results[0].altETH,
                        cryptoData.results[0].uniETH,
                        cryptoData.results[0].valr_eth,
                        cryptoData.results[0].luno_eth,
                        cryptoData.results[0].binance_eth)
                )
              }} %
            </td>
            <td>{{
                getArb(
                    getMin(cryptoData.results[0].alt_ada,
                        cryptoData.results[0].valr_ada,
                        cryptoData.results[0].kraken_ada,
                        cryptoData.results[0].binance_ada),
                    getMax(cryptoData.results[0].alt_ada,
                        cryptoData.results[0].valr_ada,
                        cryptoData.results[0].kraken_ada,
                        cryptoData.results[0].binance_ada)
                )
              }} %
            </td>
            <td>{{
                getArb(
                    getMin(cryptoData.results[0].altUSDT,
                        cryptoData.results[0].uniUSDT,
                        cryptoData.results[0].kraken_usdt,
                        cryptoData.results[0].binance_usdt),
                    getMax(cryptoData.results[0].altUSDT,
                        cryptoData.results[0].uniUSDT,
                        cryptoData.results[0].kraken_usdt,
                        cryptoData.results[0].binance_usdt)
                )
              }} %
            </td>
            <td>{{
                getArb(
                    getMin(cryptoData.results[0].altCOMP,
                        cryptoData.results[0].uniCOMP,
                        cryptoData.results[0].valr_comp,
                        cryptoData.results[0].kraken_comp,
                        cryptoData.results[0].binance_comp),

                    getMax(cryptoData.results[0].altCOMP,
                        cryptoData.results[0].uniCOMP,
                        cryptoData.results[0].valr_comp,
                        cryptoData.results[0].kraken_comp,
                        cryptoData.results[0].binance_comp)
                )
              }} %
            </td>
            <td>{{
                getArb(
                    getMin(cryptoData.results[0].altDAI,
                        cryptoData.results[0].uniDAI,
                        cryptoData.results[0].valr_dai,
                        cryptoData.results[0].kraken_dai,
                        cryptoData.results[0].binance_dai),

                    getMax(cryptoData.results[0].altDAI,
                        cryptoData.results[0].uniDAI,
                        cryptoData.results[0].valr_dai,
                        cryptoData.results[0].kraken_dai,
                        cryptoData.results[0].binance_dai),
                )
              }} %
            </td>
            <td>{{
                getArb(
                    getMin(cryptoData.results[0].alt_trx,
                        cryptoData.results[0].valr_trx,
                        cryptoData.results[0].kraken_trx,
                        cryptoData.results[0].binance_trx),

                    getMax(cryptoData.results[0].alt_trx,
                        cryptoData.results[0].valr_trx,
                        cryptoData.results[0].kraken_trx,
                        cryptoData.results[0].binance_trx)
                )
              }} %
            </td>
            <td>{{
                getArb(
                    getMin(cryptoData.results[0].alt_xrp,
                        cryptoData.results[0].valr_xrp,
                        cryptoData.results[0].kraken_xrp,
                        cryptoData.results[0].luno_xrp,
                        cryptoData.results[0].binance_xrp),

                    getMax(cryptoData.results[0].alt_xrp,
                        cryptoData.results[0].valr_xrp,
                        cryptoData.results[0].kraken_xrp,
                        cryptoData.results[0].luno_xrp,
                        cryptoData.results[0].binance_xrp)
                )
              }} %
            </td>
            <td>{{
                getArb(
                    getMin(cryptoData.results[0].altBAT,
                        cryptoData.results[0].uniBAT,
                        cryptoData.results[0].valr_bat,
                        cryptoData.results[0].kraken_bat,
                        cryptoData.results[0].binance_bat),

                    getMax(cryptoData.results[0].altBAT,
                        cryptoData.results[0].uniBAT,
                        cryptoData.results[0].valr_bat,
                        cryptoData.results[0].kraken_bat,
                        cryptoData.results[0].binance_bat)
                )
              }} %
            </td>
            <td>{{
                getArb(
                    getMin(cryptoData.results[0].alt_doge,
                        cryptoData.results[0].valr_doge,
                        cryptoData.results[0].kraken_doge,
                        cryptoData.results[0].binance_doge),
                    getMax(cryptoData.results[0].alt_doge,
                        cryptoData.results[0].valr_doge,
                        cryptoData.results[0].kraken_doge,
                        cryptoData.results[0].binance_doge)
                )
              }} %
            </td>
          </tr>
          <tr
          >
            <td>Altcoin Trader</td>
            <td>{{ cryptoData.results[0].alt_btc }}</td>
            <td>{{ cryptoData.results[0].altETH }}</td>

            <td>{{ cryptoData.results[0].alt_ada }}</td>
            <td>{{ cryptoData.results[0].altUSDT }}</td>
            <td>{{ cryptoData.results[0].altCOMP }}</td>
            <td>{{ cryptoData.results[0].altDAI }}</td>
            <td>{{ cryptoData.results[0].alt_trx }}</td>
            <td>{{ cryptoData.results[0].alt_xrp }}</td>
            <td>{{ cryptoData.results[0].altBAT }}</td>
            <td>{{ cryptoData.results[0].alt_doge }}</td>
          </tr>
          <tr>
            <td>Valr</td>
            <td>{{ cryptoData.results[0].alt_valr_btc_arb }} % {{ cryptoData.results[0].valr_btc }}</td>
            <td>{{
                getArb(parseFloat(cryptoData.results[0].altETH),
                    parseFloat(cryptoData.results[0].valr_eth))
              }} % {{ cryptoData.results[0].valr_eth }}
            </td>

            <td>{{ cryptoData.results[0].alt_valr_ada_arb }} % {{ cryptoData.results[0].valr_ada }}</td>
            <td></td>
            <td>{{
                getArb(parseFloat(cryptoData.results[0].altCOMP),
                    parseFloat(cryptoData.results[0].valr_comp))
              }} % {{ cryptoData.results[0].valr_comp }}
            </td>
            <td>{{
                getArb(parseFloat(cryptoData.results[0].altDAI),
                    parseFloat(cryptoData.results[0].valr_dai))
              }} % {{ cryptoData.results[0].valr_dai }}
            </td>
            <td>{{ cryptoData.results[0].alt_valr_trx_arb }} % {{ cryptoData.results[0].valr_trx }}</td>
            <td>{{ cryptoData.results[0].alt_valr_xrp_arb }} % {{ cryptoData.results[0].valr_xrp }}</td>
            <td>{{ cryptoData.results[0].alt_valr_bat_arb }} % {{ cryptoData.results[0].valr_bat }}</td>
            <td>{{ cryptoData.results[0].alt_valr_doge_arb }} % {{ cryptoData.results[0].valr_doge }}</td>
          </tr>


          <tr>
            <td>Uniswap</td>
            <td></td>
            <td>{{ cryptoData.results[0].arbETH }} % {{ cryptoData.results[0].uniETH }}</td>

            <td></td>
            <td>{{ cryptoData.results[0].arbUSDT }} % {{ cryptoData.results[0].uniUSDT }}</td>
            <td>{{ cryptoData.results[0].arbCOMP }} % {{ cryptoData.results[0].uniCOMP }}</td>
            <td>{{ cryptoData.results[0].arbDAI }} % {{ cryptoData.results[0].uniDAI }}</td>
            <td></td>
            <td></td>
            <td>{{ cryptoData.results[0].arbBAT }} % {{ cryptoData.results[0].uniBAT }}</td>
            <td></td>
          </tr>


          <tr>
            <td>Kraken</td>
            <td>{{ cryptoData.results[0].alt_kraken_btc_arb }} % {{ cryptoData.results[0].kraken_btc }}</td>
            <td>{{
                getArb(parseFloat(cryptoData.results[0].altETH),
                    parseFloat(cryptoData.results[0].kraken_eth))
              }} % {{ cryptoData.results[0].kraken_eth }}
            </td>

            <td>{{
                getArb(parseFloat(cryptoData.results[0].alt_ada),
                    parseFloat(cryptoData.results[0].kraken_ada))
              }} % {{ cryptoData.results[0].kraken_ada }}
            </td>
            <td>{{ cryptoData.results[0].alt_kraken_usdt_arb }} % {{ cryptoData.results[0].kraken_usdt }}</td>
            <td>{{ cryptoData.results[0].alt_kraken_comp_arb }} % {{ cryptoData.results[0].kraken_comp }}</td>
            <td>{{ cryptoData.results[0].alt_kraken_dai_arb }} % {{ cryptoData.results[0].kraken_dai }}</td>
            <td>{{
                getArb(parseFloat(cryptoData.results[0].alt_trx),
                    parseFloat(cryptoData.results[0].kraken_trx))
              }} % {{ cryptoData.results[0].kraken_trx }}
            </td>
            <td>{{ cryptoData.results[0].alt_kraken_xrp_arb }} % {{ cryptoData.results[0].kraken_xrp }}</td>
            <td>{{ cryptoData.results[0].alt_kraken_bat_arb }} % {{ cryptoData.results[0].kraken_bat }}</td>
            <td>{{ cryptoData.results[0].alt_kraken_doge_arb }} % {{ cryptoData.results[0].kraken_doge }}</td>
          </tr>

          <tr>
            <td>Luno</td>
            <td>{{
                getArb(parseFloat(cryptoData.results[0].alt_btc),
                    parseFloat(cryptoData.results[0].luno_btc))
              }} % {{ cryptoData.results[0].luno_btc }}
            </td>
            <td>{{
                getArb(parseFloat(cryptoData.results[0].altETH),
                    parseFloat(cryptoData.results[0].luno_eth))
              }} % {{ cryptoData.results[0].luno_eth }}
            </td>

            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{
                getArb(parseFloat(cryptoData.results[0].alt_xrp),
                    parseFloat(cryptoData.results[0].luno_xrp))
              }} % {{ cryptoData.results[0].luno_xrp }}
            </td>
            <td></td>
            <td></td>
          </tr>


          <tr>
            <td>Binance</td>
            <td>{{ cryptoData.results[0].alt_binance_btc_arb }} % {{ cryptoData.results[0].binance_btc }}</td>
            <td>{{ cryptoData.results[0].alt_binance_eth_arb }} % {{ cryptoData.results[0].binance_eth }}</td>
            <td>{{ cryptoData.results[0].alt_binance_ada_arb }} % {{ cryptoData.results[0].binance_ada }}</td>
            <td>{{ cryptoData.results[0].alt_binance_usdt_arb }} % {{ cryptoData.results[0].binance_usdt }}</td>
            <td>{{ cryptoData.results[0].alt_binance_comp_arb }} % {{ cryptoData.results[0].binance_comp }}</td>
            <td>{{ cryptoData.results[0].alt_binance_dai_arb }} % {{ cryptoData.results[0].binance_dai }}</td>
            <td>{{ cryptoData.results[0].alt_binance_trx_arb }} % {{ cryptoData.results[0].binance_trx }}</td>
            <td>{{ cryptoData.results[0].alt_binance_xrp_arb }} % {{ cryptoData.results[0].binance_xrp }}</td>
            <td>{{ cryptoData.results[0].alt_binance_bat_arb }} % {{ cryptoData.results[0].binance_bat }}</td>
            <td>{{ cryptoData.results[0].alt_binance_doge_arb }} % {{ cryptoData.results[0].binance_doge }}</td>
          </tr>

          </tbody>
        </template>
      </v-simple-table>
      <hr>
      <hr>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">

            </th>
            <th class="text-left">
              Altcoin vs kraken
            </th>

            <th class="text-left">
              Altcoin vs Valr
            </th>

            <th class="text-left">
              Altcoin vs Uniswap
            </th>

            <th class="text-left">
              Altcoin vs Luno
            </th>

            <th class="text-left">
              Altcoin vs Binance
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Highest</td>
            <td>{{getMaxName([cryptoData.results[0].alt_kraken_btc_arb, "BTC"],
                [getArb(parseFloat(cryptoData.results[0].altETH),
                    parseFloat(cryptoData.results[0].kraken_eth)), "ETH"],

                [getArb(parseFloat(cryptoData.results[0].alt_ada),
                    parseFloat(cryptoData.results[0].kraken_ada)), "ADA"],
                [cryptoData.results[0].alt_kraken_usdt_arb, "USDT"],

                    [cryptoData.results[0].alt_kraken_comp_arb, "COMP"],
                    [cryptoData.results[0].alt_kraken_dai_arb, "DAI"],
                    [getArb(parseFloat(cryptoData.results[0].alt_trx),
                        parseFloat(cryptoData.results[0].kraken_trx)), "TRX"],
                    [cryptoData.results[0].alt_kraken_xrp_arb, "XRP"],
                    [cryptoData.results[0].alt_kraken_bat_arb, "BAT"],
                    [cryptoData.results[0].alt_kraken_doge_arb, "DOGE"],)}}</td>

            <td>{{getMaxName(
                [cryptoData.results[0].alt_valr_btc_arb,"BTC"],

                [getArb(parseFloat(cryptoData.results[0].altETH),
                    parseFloat(cryptoData.results[0].valr_eth)), "ETH"],
                [cryptoData.results[0].alt_valr_ada_arb, "ADA"],
                [getArb(parseFloat(cryptoData.results[0].altCOMP),
                    parseFloat(cryptoData.results[0].valr_comp)), "COMP"],
                [getArb(parseFloat(cryptoData.results[0].altDAI),
                    parseFloat(cryptoData.results[0].valr_dai)), "DAI"],
                [cryptoData.results[0].alt_valr_trx_arb, "TRX"],
                [cryptoData.results[0].alt_valr_xrp_arb, "XRP"],
                [cryptoData.results[0].alt_valr_bat_arb, "BAT"],
                [cryptoData.results[0].alt_valr_doge_arb, "DOGE"],
            )}}</td>

            <td>{{getMaxName(
                [cryptoData.results[0].arbUSDT, "USDT"],
                [cryptoData.results[0].arbETH, "ETH"],
                [cryptoData.results[0].arbCOMP, "COMP"],
                [cryptoData.results[0].arbDAI, "DAI"],
                [cryptoData.results[0].arbBAT, "BAT"],)}}</td>

          <td>{{getMaxName(
              [getArb(parseFloat(cryptoData.results[0].alt_btc),
              parseFloat(cryptoData.results[0].luno_btc)), "BTC"],
              [getArb(parseFloat(cryptoData.results[0].altETH),
                  parseFloat(cryptoData.results[0].luno_eth)), "ETH"],
              [getArb(parseFloat(cryptoData.results[0].alt_xrp),
                  parseFloat(cryptoData.results[0].luno_xrp)), "XRP"]

          )}}</td>

            <td>{{getMaxName(
                [cryptoData.results[0].alt_binance_btc_arb, "BTC"],
                [cryptoData.results[0].alt_binance_eth_arb, "ETH"],
                [cryptoData.results[0].alt_binance_ada_arb, "ADA"],
                [cryptoData.results[0].alt_binance_usdt_arb, "USDT"],
                [cryptoData.results[0].alt_binance_comp_arb, "COMP"],
                [cryptoData.results[0].alt_binance_dai_arb, "DAI"],
                [cryptoData.results[0].alt_binance_trx_arb, "TRX"],
                [cryptoData.results[0].alt_binance_xrp_arb, "XRP"],
                [cryptoData.results[0].alt_binance_bat_arb, "BAT"],
                [cryptoData.results[0].alt_binance_doge_arb, "DOGE"],
            )}}</td>
          </tr>
          <tr>
            <td>Lowest</td>
            <td>
              {{getMinName([cryptoData.results[0].alt_kraken_btc_arb, "BTC"],
                [getArb(parseFloat(cryptoData.results[0].altETH),
                    parseFloat(cryptoData.results[0].kraken_eth)), "ETH"],

                [getArb(parseFloat(cryptoData.results[0].alt_ada),
                    parseFloat(cryptoData.results[0].kraken_ada)), "ADA"],
                [cryptoData.results[0].alt_kraken_usdt_arb, "USDT"],

                [cryptoData.results[0].alt_kraken_comp_arb, "COMP"],
                [cryptoData.results[0].alt_kraken_dai_arb, "DAI"],
                [getArb(parseFloat(cryptoData.results[0].alt_trx),
                    parseFloat(cryptoData.results[0].kraken_trx)), "TRX"],
                [cryptoData.results[0].alt_kraken_xrp_arb, "XRP"],
                [cryptoData.results[0].alt_kraken_bat_arb, "BAT"],
                [cryptoData.results[0].alt_kraken_doge_arb, "DOGE"],)}}

            </td>
            <td>{{getMinName(
                [cryptoData.results[0].alt_valr_btc_arb,"BTC"],

                [getArb(parseFloat(cryptoData.results[0].altETH),
                    parseFloat(cryptoData.results[0].valr_eth)), "ETH"],
                [cryptoData.results[0].alt_valr_ada_arb, "ADA"],
                [getArb(parseFloat(cryptoData.results[0].altCOMP),
                    parseFloat(cryptoData.results[0].valr_comp)), "COMP"],
                [getArb(parseFloat(cryptoData.results[0].altDAI),
                    parseFloat(cryptoData.results[0].valr_dai)), "DAI"],
                [cryptoData.results[0].alt_valr_trx_arb, "TRX"],
                [cryptoData.results[0].alt_valr_xrp_arb, "XRP"],
                [cryptoData.results[0].alt_valr_bat_arb, "BAT"],
                [cryptoData.results[0].alt_valr_doge_arb, "DOGE"],
            )}}</td>

            <td>{{getMinName(
                [cryptoData.results[0].arbUSDT, "USDT"],
                [cryptoData.results[0].arbETH, "ETH"],
                [cryptoData.results[0].arbCOMP, "COMP"],
                [cryptoData.results[0].arbDAI, "DAI"],
                [cryptoData.results[0].arbBAT, "BAT"],)}}</td>

            <td>{{getMinName(
                [getArb(parseFloat(cryptoData.results[0].alt_btc),
                    parseFloat(cryptoData.results[0].luno_btc)), "BTC"],
                [getArb(parseFloat(cryptoData.results[0].altETH),
                    parseFloat(cryptoData.results[0].luno_eth)), "ETH"],
                [getArb(parseFloat(cryptoData.results[0].alt_xrp),
                    parseFloat(cryptoData.results[0].luno_xrp)), "XRP"]

            )}}</td>

            <td>{{getMinName(
                [cryptoData.results[0].alt_binance_btc_arb, "BTC"],
                [cryptoData.results[0].alt_binance_eth_arb, "ETH"],
                [cryptoData.results[0].alt_binance_ada_arb, "ADA"],
                [cryptoData.results[0].alt_binance_usdt_arb, "USDT"],
                [cryptoData.results[0].alt_binance_comp_arb, "COMP"],
                [cryptoData.results[0].alt_binance_dai_arb, "DAI"],
                [cryptoData.results[0].alt_binance_trx_arb, "TRX"],
                [cryptoData.results[0].alt_binance_xrp_arb, "XRP"],
                [cryptoData.results[0].alt_binance_bat_arb, "BAT"],
                [cryptoData.results[0].alt_binance_doge_arb, "DOGE"],
            )}}</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>

      <v-simple-table v-if="cryptoData.length !==0 && radioGroup===1" fixed-header height="600px" class="pa-9">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              Time Stamp
            </th>
            <!--            BTC-->
            <th class="text-left">
              Alt (BTC)
            </th>
            <th class="text-left">
              Valr (BTC)
            </th>
            <th class="text-left">
              Kraken (BTC)
            </th>
            <!--            ETH-->
            <th class="text-left">
              Alt (ETH)
            </th>
            <th class="text-left">
              Uniswap (ETH)
            </th>
            <!--            29 april added -->
            <th class="text-left">
              Valr (ETH)
            </th>

            <th class="text-left">
              Alt (COMP)
            </th>
            <th class="text-left">
              Uniswap (COMP)
            </th>
            <!--            29 april added-->
            <th class="text-left">
              Valr (COMP)
            </th>

            <th class="text-left">
              Kraken (COMP)
            </th>

            <th class="text-left">
              Alt (USDT)
            </th>
            <th class="text-left">
              Uniswap (USDT)
            </th>
            <!--            29 april added-->
            <th class="text-left">
              Kraken (USDT)
            </th>
            <th class="text-left">
              Alt (DAI)
            </th>
            <th class="text-left">
              Uniswap (DAI)
            </th>

            <!--            29 april added -->
            <th class="text-left">
              Valr (DAI)
            </th>
            <th class="text-left">
              Kraken (DAI)
            </th>

            <!--            arbBAT: [],-->
            <th class="text-left">
              Alt (BAT)
            </th>
            <th class="text-left">
              Uniswap (BAT)
            </th>

            <!--            alt_valr_doge_arb: [],-->
            <th class="text-left">
              Alt (DOGE)
            </th>
            <th class="text-left">
              Valr (DOGE)
            </th>
            <th class="text-left">
              Kraken (DOGE)
            </th>
            <!--            alt_valr_trx_arb: [],-->

            <th class="text-left">
              Alt (TRX)
            </th>
            <th class="text-left">
              Valr (TRX)
            </th>
            <!--            29 added-->
            <th class="text-left">
              Kraken (TRX)
            </th>


            <!--            alt_valr_xrp_arb: [],-->

            <th class="text-left">
              Alt (XRP)
            </th>
            <th class="text-left">
              Valr (XRP)
            </th>

            <th class="text-left">
              Kraken (XRP)
            </th>
            <!--            alt_valr_ada_arb: [],-->
            <th class="text-left">
              Alt (ADA)
            </th>
            <th class="text-left">
              Valr (ADA)
            </th>
            <!--            29 april added-->
            <th class="text-left">
              Kraken (ADA)
            </th>
            <!--            alt_valr_bat_arb: [],-->
            <th class="text-left">
              Alt (BAT)
            </th>
            <th class="text-left">
              Valr (BAT)
            </th>
            <!--           29 april added -->
            <th class="text-left">
              Kraken (BAT)
            </th>

            <!--            29 april-->
            <th class="text-left">
              Alt (XLM)
            </th>
            <th class="text-left">
              Valr (XLM)
            </th>
            <th class="text-left">
              Kraken (XLM)
            </th>

            <th class="text-left">
              ETH(%)
            </th>
            <th class="text-left">
              COMP(%)
            </th>

            <th class="text-left">
              USDT(%)
            </th>
            <th class="text-left">
              DAI(%)
            </th>

            <!--            arbBAT: [],-->
            <th class="text-left">
              BAT(%)
            </th>
            <!--            alt_valr_doge_arb: [],-->
            <th class="text-left">
              alt valr DOGE(%)
            </th>
            <th class="text-left">
              alt kraken DOGE(%)
            </th>
            <!--            alt_valr_trx_arb: [],-->
            <th class="text-left">
              alt valr TRX(%)
            </th>
            <!--            alt_valr_xrp_arb: [],-->
            <th class="text-left">
              alt valr XRP(%)
            </th>
            <!--            alt_valr_ada_arb: [],-->
            <th class="text-left">
              alt valr ADA(%)
            </th>
            <!--            alt_valr_bat_arb: [],-->
            <th class="text-left">
              alt valr BAT(%)
            </th>

            <th class="text-left">
              Alt vs Kraken COMP(%)
            </th>
            <th class="text-left">
              Alt vs Kraken DAI(%)
            </th>
            <th class="text-left">
              Alt vs Kraken USDT(%)
            </th>
            <th class="text-left">
              Alt vs Kraken BAT(%)
            </th>
            <th class="text-left">
              Kraken vs Valr DOGE(%)
            </th>
            <th class="text-left">
              Kraken vs Valr TRX(%)
            </th>
            <th class="text-left">
              Kraken vs Valr ADA(%)
            </th>
            <th class="text-left">
              Kraken vs valr XLM(%)
            </th>
            <th class="text-left">
              Kraken vs Valr BAT(%)
            </th>
            <th class="text-left">
              Kraken vs Valr COMP(%)
            </th>
            <th class="text-left">
              Kraken vs Valr DAI(%)
            </th>


            <th class="text-left">
              Altcoin vs Valr BTC(%)
            </th>

            <th class="text-left">
              Altcoin vs Kraken BTC(%)
            </th>

            <th class="text-left">
              Altcoin vs Kraken XRP(%)
            </th>

          </tr>
          </thead>
          <tbody>
          <tr
              v-for="item in cryptoData.results"
              :key="item.id"
          >
            <td>{{ convertDateTime(item.timestamp) }}</td>

            <!--            BTC-->
            <td>{{ item.alt_btc }}</td>
            <td>{{ item.valr_btc }}</td>
            <td>{{ item.kraken_btc }}</td>
            <!--            ETH-->
            <td>{{ item.altETH }}</td>
            <td>{{ item.uniETH }}</td>
            <!--           29 april added -->
            <td>{{ item.valr_eth }}</td>


            <td>{{ item.altCOMP }}</td>
            <td>{{ item.uniCOMP }}</td>
            <!--            29 april added -->
            <td>{{ item.valr_comp }}</td>
            <td>{{ item.kraken_comp }}</td>

            <td>{{ item.altUSDT }}</td>
            <td>{{ item.uniUSDT }}</td>
            <td>{{ item.kraken_usdt }}</td>

            <!--            DAI-->
            <td>{{ item.altDAI }}</td>
            <td>{{ item.uniDAI }}</td>
            <!--            29 april added -->
            <td>{{ item.valr_dai }}</td>
            <td>{{ item.kraken_dai }}</td>

            <!--            added-->

            <!--            arbBAT: [],-->
            <td>{{ item.altBAT }}</td>
            <td>{{ item.uniBAT }}</td>
            <!--            alt_valr_doge_arb: [],-->
            <td>{{ item.alt_doge }}</td>
            <td>{{ item.valr_doge }}</td>
            <td>{{ item.kraken_doge }}</td>
            <!--            alt_valr_trx_arb: [],-->
            <td>{{ item.alt_trx }}</td>
            <td>{{ item.valr_trx }}</td>
            <!--            29 april added -->
            <td>{{ item.kraken_trx }}</td>
            <!--            alt_valr_xrp_arb: [],-->
            <td>{{ item.alt_xrp }}</td>
            <td>{{ item.valr_xrp }}</td>

            <td>{{ item.kraken_xrp }}</td>
            <!--            alt_valr_ada_arb: [],-->
            <td>{{ item.alt_ada }}</td>
            <td>{{ item.valr_ada }}</td>
            <!--            29 april added -->
            <td>{{ item.kraken_ada }}</td>
            <!--            alt_valr_bat_arb: [],-->
            <td>{{ item.altBAT }}</td>
            <td>{{ item.valr_bat }}</td>
            <!--            29 april added -->
            <td>{{ item.kraken_bat }}</td>

            <!--            29 april-->
            <td>{{ item.alt_xlm }}</td>
            <td>{{ item.valr_xlm }}</td>
            <td>{{ item.kraken_xlm }}</td>

            <td :class="{red: item.altETH < item.uniETH, green: item.altETH > item.uniETH}">{{ item.arbETH }}</td>
            <td :class="{red: item.altCOMP < item.uniCOMP, green: item.altCOMP > item.uniCOMP}">{{ item.arbCOMP }}</td>
            <td :class="{red: item.altUSDT < item.uniUSDT, green: item.altUSDT > item.uniUSDT}">{{ item.arbUSDT }}</td>
            <td :class="{red: item.altDAI < item.uniDAI, green: item.altDAI > item.uniDAI}">{{ item.arbDAI }}</td>

            <!--            arbBAT: [],-->
            <td :class="{red: item.altBAT < item.uniBAT, green: item.altBAT > item.uniBAT}">{{ item.arbBAT }}</td>
            <!--            alt_valr_doge_arb: [],-->
            <td :class="{red: item.alt_doge < item.valr_doge, green: item.alt_doge > item.valr_doge}">
              {{ item.alt_valr_doge_arb }}
            </td>
            <!--            alt_kraken-->
            <td :class="{red: item.alt_doge < item.kraken_doge, green: item.alt_doge > item.kraken_doge}">
              {{ item.alt_kraken_doge_arb }}
            </td>
            <!--            alt_valr_trx_arb: [],-->
            <td :class="{red: item.alt_trx < item.valr_trx, green: item.alt_trx > item.valr_trx}">
              {{ item.alt_valr_trx_arb }}
            </td>
            <!--            alt_valr_xrp_arb: [],-->
            <td :class="{red: item.alt_xrp < item.valr_xrp, green: item.alt_xrp > item.valr_xrp}">
              {{ item.alt_valr_xrp_arb }}
            </td>
            <!--            alt_valr_ada_arb: [],-->
            <td :class="{red: item.alt_ada < item.valr_ada, green: item.alt_ada > item.valr_ada}">
              {{ item.alt_valr_ada_arb }}
            </td>
            <!--            alt_valr_bat_arb: [],-->
            <td :class="{red: item.altBAT < item.valr_bat, green: item.altBAT > item.valr_bat}">{{
                item.alt_valr_bat_arb
              }}
            </td>

            <!--            29 april added-->
            <!--            alt_kraken_comp_arb: [],-->
            <td :class="{red: item.altCOMP < item.kraken_comp, green: item.altCOMP > item.kraken_comp}">{{
                item.alt_kraken_comp_arb
              }}
            </td>
            <!--            alt_kraken_dai_arb: [],-->
            <td :class="{red: item.altDAI < item.kraken_dai, green: item.altDAI > item.kraken_dai}">{{
                item.alt_kraken_dai_arb
              }}
            </td>
            <!--            alt_kraken_usdt_arb: [],-->
            <td :class="{red: item.altUSDT < item.kraken_usdt, green: item.altUSDT > item.kraken_usdt}">{{
                item.alt_kraken_usdt_arb
              }}
            </td>
            <!--            alt_kraken_bat_arb: [],-->
            <td :class="{red: item.altBAT < item.kraken_bat, green: item.altBAT > item.kraken_bat}">{{
                item.alt_kraken_bat_arb
              }}
            </td>
            <!--            kraken_valr_doge_arb: [],-->
            <td :class="{red: item.kraken_bat < item.valr_bat, green: item.kraken_bat > item.valr_bat}">{{
                item.kraken_valr_doge_arb
              }}
            </td>
            <!--            kraken_valr_trx_arb: [],-->
            <td :class="{red: item.kraken_trx < item.valr_trx, green: item.kraken_trx > item.valr_trx}">{{
                item.kraken_valr_trx_arb
              }}
            </td>
            <!--            kraken_valr_ada_arb: [],-->
            <td :class="{red: item.kraken_ada < item.valr_ada, green: item.kraken_ada > item.valr_ada}">{{
                item.kraken_valr_ada_arb
              }}
            </td>
            <!--            kraken_valr_xlm_arb: [],-->
            <td :class="{red: item.kraken_xlm < item.valr_xlm, green: item.kraken_xlm > item.valr_xlm}">{{
                item.kraken_valr_xlm_arb
              }}
            </td>
            <!--            kraken_valr_bat_arb: [],-->
            <td :class="{red: item.kraken_bat < item.valr_bat, green: item.kraken_bat > item.valr_bat}">{{
                item.kraken_valr_bat_arb
              }}
            </td>
            <!--            kraken_valr_comp_arb: [],-->
            <td :class="{red: item.kraken_comp < item.valr_comp, green: item.kraken_comp > item.valr_comp}">{{
                item.kraken_valr_comp_arb
              }}
            </td>
            <!--            kraken_valr_dai_arb: [],-->
            <td :class="{red: item.kraken_dai < item.valr_dai, green: item.kraken_dai > item.valr_dai}">{{
                item.kraken_valr_dai_arb
              }}
            </td>
            <!--            alt_valr_btc_arb: [],-->
            <td :class="{red: item.alt_btc < item.valr_btc, green: item.alt_btc > item.valr_btc}">{{
                item.alt_valr_btc_arb
              }}
            </td>
            <!--            alt_kraken_btc_arb: [],-->
            <td :class="{red: item.alt_btc < item.kraken_btc, green: item.alt_btc > item.kraken_btc}">{{
                item.alt_kraken_btc_arb
              }}
            </td>
            <!--            alt_kraken_xrp_arb: [],-->
            <td :class="{red: item.alt_xrp < item.kraken_xrp, green: item.alt_xrp > item.kraken_xrp}">{{
                item.alt_kraken_xrp_arb
              }}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>

      <div class="d-flex justify-space-around">
        <v-btn @click="getPrevCryptoData" :disabled="cryptoData.previous===null" color="primary">Prev</v-btn>
        Total Pages: {{ cryptoData.count }}
        <v-btn @click="getNextCryptoData" :disabled="cryptoData.next===null" color="primary">Next</v-btn>
      </div>

      <v-overlay
          :value="getLoadingState"
      >
        <v-progress-circular
            indeterminate
            size="80"
        >
          Loading...
        </v-progress-circular>
      </v-overlay>
    </div>

    <div v-else style="height:50vh">
      <v-container>
        <v-alert
            outlined
            type="warning"
            prominent
            border="left"
        >
          You have no access to this page. This page only for super user. Your are not a super user.
          <br>
          Visit our products page
          <router-link to="/products">click here.</router-link>
        </v-alert>
      </v-container>
    </div>

    <Footer class="mt-6"/>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import {mapActions, mapGetters} from "vuex";
import AltUniswapLineChart from "@/components/AltUniswapLineChart";
import AltUniswapChartTwo from "@/components/AltUniswapChartTwo";
import EthGasLineChart from "@/components/EthGasLineChart";

var moment = require('moment');

export default {
  name: "AltUniswapView",

  created() {
    document.title = "Arbitrage calculator - Ovex Broker";
  },

  components: {
    EthGasLineChart,
    AltUniswapLineChart,
    AltUniswapChartTwo,
    Footer,
  },
  data() {
    return {
      itemSize: 228,
      cryptoData: [],
      ethGasData: [],

      lastBlock: [],
      safeGasPrice: [],
      proposeGasPrice: [],
      fastGasPrice: [],
      ethPrice: [],

      arbETH: [],
      arbCOMP: [],
      arbUSDT: [],
      arbDAI: [],
      arbBAT: [],
      alt_valr_doge_arb: [],
      alt_valr_trx_arb: [],
      alt_valr_xrp_arb: [],
      alt_valr_ada_arb: [],
      alt_valr_bat_arb: [],
      alt_kraken_doge_arb: [],
      // 28 april
      alt_kraken_comp_arb: [],
      alt_kraken_dai_arb: [],
      alt_kraken_usdt_arb: [],
      alt_kraken_bat_arb: [],
      kraken_valr_doge_arb: [],
      kraken_valr_trx_arb: [],
      kraken_valr_ada_arb: [],
      kraken_valr_xlm_arb: [],
      kraken_valr_bat_arb: [],
      kraken_valr_comp_arb: [],
      kraken_valr_dai_arb: [],
      // 1 may
      alt_valr_btc_arb: [],
      alt_kraken_btc_arb: [],
      alt_kraken_xrp_arb: [],

      // 2 may
      btc_percentage: [],
      eth_percentage: [],
      ada_percentage: [],
      usdt_percentage: [],
      comp_percentage: [],
      dai_percentage: [],
      trx_percentage: [],
      xrp_percentage: [],
      bat_percentage: [],
      doge_percentage: [],

      timestamps: [],
      ethGasTimestamp: [],
      startingAmount: 100000,
      bankFee: 500,
      lastCryptoData: {},
      linearChartUpdateKey: 0,
      // highestArbs: {},
      highestArbsLoadingState: false,
      radioGroup: 1,
    }
  },
  mounted() {
    if (this.isSubscriber && this.isSuperUser) {
      //   this.getLastCryptoData()
      this.getCryptoData()
      this.getEthGasData()
      // this.getHighestArbs()
    }
  },
  computed: {
    ...mapGetters('payment', ['isSubscriber']),
    ...mapGetters('baseUrl', ['getBaseUrl']),
    ...mapGetters('loadingState', ['getLoadingState']),
    ...mapGetters('user', ['isSuperUser']),
  },
  methods: {

    ...mapActions('user', ['setSnackBarData']),

    ...mapActions('loadingState', ['setLoadingState']),

    getMax() {
      return Math.max(...arguments)
    },

    getMin() {
      return Math.min(...arguments)
    },

    getMaxName(){
      let arbs = [];
      let coin = [];
      for (let i=0; i < arguments.length; i++) {
        arbs.push(parseFloat(arguments[i][0]))
        coin.push(arguments[i][1])
      }
      let maxArb = Math.max(...arbs)
      return maxArb + " % " + "(" + coin[arbs.indexOf(maxArb)]+ ")";
    },

    getMinName(){
      let arbs = [];
      let coin = [];
      for (let i=0; i < arguments.length; i++) {
        arbs.push(parseFloat(arguments[i][0]))
        coin.push(arguments[i][1])
      }
      let minArb = Math.min(...arbs)
      return minArb + " % " + "(" + coin[arbs.indexOf(minArb)] + ")";
    },

    getArb(x, y) {
      let sub = x - y
      let add = ((x + y) / 2)
      return ((sub / add) * 100).toPrecision(4)
    },

    getCryptoData() {

      this.setLoadingState(true)
      this.$axios.get(this.getBaseUrl + 'altcoin_uniswap/?page_size=' + this.itemSize).then(res => {
        this.cryptoData = res.data;
        this.setdata()
      }).catch(err => {

        if (err.response) {
          this.setSnackBarData(err.response.data)
          this.setSnackBarState(true)
        }

        console.log(err)
      }).finally(() => {
        this.setLoadingState(false)
      })
    },

    getEthGasData() {
      // this.setLoadingState(true)
      this.$axios.get(this.getBaseUrl + 'altcoin_uniswap/eth-gas/?page_size=' + this.itemSize).then(res => {
        this.ethGasData = res.data;
        this.setETHGasData()
      }).catch(err => {

        if (err.response) {
          this.setSnackBarData(err.response.data)
          this.setSnackBarState(true)
        }

        console.log(err)
      }).finally(() => {
        // this.setLoadingState(false)
      })
    },

    getPrevCryptoData() {

      if (this.cryptoData.previous === null) {
        return;
      }

      const url = this.cryptoData.previous;


      this.setLoadingState(true)
      this.$axios.get(url).then(res => {
        this.cryptoData = res.data;
        this.setdata()
      }).catch(err => {

        if (err.response) {
          this.setSnackBarData(err.response.data)
          this.setSnackBarState(true)
        }
        console.log(err)
      }).finally(() => {
        this.setLoadingState(false)
      })


    },

    getNextCryptoData() {
      if (this.cryptoData.next === null) {
        return;
      }
      const url = this.cryptoData.next;
      this.setLoadingState(true)
      this.$axios.get(url).then(res => {
        this.cryptoData = res.data;
        this.setdata()
      }).catch(err => {

        if (err.response) {
          this.setSnackBarData(err.response.data)
          this.setSnackBarState(true)
        }
        console.log(err)
      }).finally(() => {
        this.setLoadingState(false)
      })
    },



    getPrevETHGasData() {

      if (this.ethGasData.previous === null) {
        return;
      }

      const url = this.ethGasData.previous;


      this.setLoadingState(true)
      this.$axios.get(url).then(res => {
        this.ethGasData = res.data;
        this.setETHGasData()
      }).catch(err => {

        if (err.response) {
          this.setSnackBarData(err.response.data)
          this.setSnackBarState(true)
        }
        console.log(err)
      }).finally(() => {
        this.setLoadingState(false)
      })


    },

    getNextETHGasData() {
      if (this.ethGasData.next === null) {
        return;
      }
      const url = this.ethGasData.next;
      this.setLoadingState(true)
      this.$axios.get(url).then(res => {
        this.ethGasData = res.data;
        this.setETHGasData()
      }).catch(err => {

        if (err.response) {
          this.setSnackBarData(err.response.data)
          this.setSnackBarState(true)
        }
        console.log(err)
      }).finally(() => {
        this.setLoadingState(false)
      })
    },


    getLastCryptoData() {
      this.$axios.get(this.getBaseUrl + 'altcoin_uniswap/last/').then(res => {
        this.lastCryptoData = res.data;
      }).catch(err => {
        if (err.response) {
          this.setSnackBarData(err.response.data)
          this.setSnackBarState(true)
        }
        console.log(err)
      }).finally(() => {
      })
    },

    convertDateTime(time) {
      return moment(time).format('MMMM Do YYYY, h:mm:ss a')
    },

    convertTime(time) {
      return moment(time).format('h:mm:ss a')
    },

    setdata() {
      this.arbETH = []
      this.arbCOMP = []
      this.arbUSDT = []
      this.arbDAI = []
      this.arbBAT = []

      this.alt_valr_doge_arb = []
      this.alt_valr_trx_arb = []
      this.alt_valr_xrp_arb = []
      this.alt_valr_ada_arb = []
      this.alt_valr_bat_arb = []
      this.alt_kraken_doge_arb = []
      // added on 28 april
      this.alt_kraken_comp_arb = []
      this.alt_kraken_dai_arb = []
      this.alt_kraken_usdt_arb = []
      this.alt_kraken_bat_arb = []
      this.kraken_valr_doge_arb = []
      this.kraken_valr_trx_arb = []
      this.kraken_valr_ada_arb = []
      this.kraken_valr_xlm_arb = []
      this.kraken_valr_bat_arb = []
      this.kraken_valr_comp_arb = []
      this.kraken_valr_dai_arb = []
      // 1 may
      this.alt_valr_btc_arb = []
      this.alt_kraken_btc_arb = []
      this.alt_kraken_xrp_arb = []

      // 2 may
      this.btc_percentage = []
      this.eth_percentage = []
      this.ada_percentage = []
      this.usdt_percentage = []
      this.comp_percentage = []
      this.dai_percentage = []
      this.trx_percentage = []
      this.xrp_percentage = []
      this.bat_percentage = []
      this.doge_percentage = []

      this.timestamps = []
      this.cryptoData.results.forEach(item => {

        this.arbETH.push(item.arbETH)
        this.arbCOMP.push(item.arbCOMP)
        this.arbUSDT.push(item.arbUSDT)
        this.arbDAI.push(item.arbDAI)
        this.arbBAT.push(item.arbBAT)
        this.alt_valr_doge_arb.push(item.alt_valr_doge_arb)
        this.alt_valr_trx_arb.push(item.alt_valr_trx_arb)
        this.alt_valr_xrp_arb.push(item.alt_valr_xrp_arb)
        this.alt_valr_ada_arb.push(item.alt_valr_ada_arb)
        this.alt_valr_bat_arb.push(item.alt_valr_bat_arb)
        this.alt_kraken_doge_arb.push(item.alt_kraken_doge_arb)
        // added on 28 april
        this.alt_kraken_comp_arb.push(item.alt_kraken_comp_arb)
        this.alt_kraken_dai_arb.push(item.alt_kraken_dai_arb)
        this.alt_kraken_usdt_arb.push(item.alt_kraken_usdt_arb)
        this.alt_kraken_bat_arb.push(item.alt_kraken_bat_arb)
        this.kraken_valr_doge_arb.push(item.kraken_valr_doge_arb)
        this.kraken_valr_trx_arb.push(item.kraken_valr_trx_arb)
        this.kraken_valr_ada_arb.push(item.kraken_valr_ada_arb)
        this.kraken_valr_xlm_arb.push(item.kraken_valr_xlm_arb)
        this.kraken_valr_bat_arb.push(item.kraken_valr_bat_arb)
        this.kraken_valr_comp_arb.push(item.kraken_valr_comp_arb)
        this.kraken_valr_dai_arb.push(item.kraken_valr_dai_arb)
        // 1 may
        this.alt_valr_btc_arb.push(item.alt_valr_btc_arb)
        this.alt_kraken_btc_arb.push(item.alt_kraken_btc_arb)
        this.alt_kraken_xrp_arb.push(item.alt_kraken_xrp_arb)

        // 2 may
        this.btc_percentage.push(item.btc_percentage)
        this.eth_percentage.push(item.eth_percentage)
        this.ada_percentage.push(item.ada_percentage)
        this.usdt_percentage.push(item.usdt_percentage)
        this.comp_percentage.push(item.comp_percentage)
        this.dai_percentage.push(item.dai_percentage)
        this.trx_percentage.push(item.trx_percentage)
        this.xrp_percentage.push(item.xrp_percentage)
        this.bat_percentage.push(item.bat_percentage)
        this.doge_percentage.push(item.doge_percentage)

        this.timestamps.push(moment(item.timestamp).format('MMMM Do YYYY, h:mm:ss a'))

      })
      this.linearChartUpdateKey += 1;
    },

    setETHGasData() {

      this.lastBlock = []
      this.safeGasPrice = []
      this.proposeGasPrice = []
      this.fastGasPrice = []
      this.ethPrice = []
      this.ethGasTimestamp = []
      this.ethGasData.results.forEach((item) => {
        this.lastBlock.push(item.lastBlock)
        this.safeGasPrice.push(item.safeGasPrice)
        this.proposeGasPrice.push(item.proposeGasPrice)
        this.fastGasPrice.push(item.fastGasPrice)
        this.ethPrice.push(item.ethPrice)
        this.ethGasTimestamp.push(moment(item.timestamp).format('MMMM Do YYYY, h:mm:ss a'))
      })

      this.linearChartUpdateKey += 1;
    }
  }
}
</script>

<style scoped>
/*.red-color{*/
/*  background-color: #dc5f5f;*/
/*}*/

/*.green{*/
/*  background-color: #447e44;*/

/*}*/

</style>